import React from "react"
import { graphql, Link } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import DateIcon from "../assets/svg/date.inline.svg"

class Etusivu extends React.Component {
  render() {
  const page = get(this, 'props.data.page')
  const allWpPost = get(this, 'props.data.allWpPost')

  return (
    <Layout bodyClass={`page-template-default page wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
      <GatsbySeo 
        title={page.seo.title} description={page.seo.metaDesc} 
        canonical={`https://www.nettikasinolla.com/`}
      />
      <article className={`frontpage page type-page status-publish hentry`}> 
        <header className="entry-header has-text-align-center header-footer-group">
          <div className="entry-header-inner section-inner medium">
            <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: page.title }} />
          </div>
        </header>
        <div className="post-inner thin">
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
        <h3 className="entry-title heading-size-3 header3">Viimeisimmät artikkelit</h3>
        {allWpPost.edges.map(({node})=> (
        <article key={node.databaseId} className={`post-${node.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized archive-post`} id={`post-${node.databaseId}`}>
          <header className="entry-header has-text-align-center">
            <div className="entry-header-inner section-inner medium">
              {node.featuredImage !== null &&
                <img src={node.featuredImage.node.mediaItemUrl} className="featured" alt={node.featuredImage.node.altText}/>
              }
              <h2 className="entry-title heading-size-2">
                <Link to={node.uri}
                  dangerouslySetInnerHTML={{ __html: node.title }}
                />
              </h2>
              <span className="meta-date"><DateIcon /> {node.date}</span>
            </div>
          </header>
          <div className="post-inner thin ">
            <div className="entry-content">
              <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          </div>
        </article>
        ))}
      </article>
    </Layout>
  )
  }
}
export default Etusivu

export const query = graphql`
query FrontPage {
    page: wpPage(title: {eq: "Nettikasinolla.com"}) {
      id
      title
      content
      seo {
        metaDesc
        title
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          slug
        }
      }
    }
    allWpPost(limit: 3) {
      edges {
        node {
          id
          title
          content
          databaseId
          date (formatString: "DD.MM.YYYY")
          excerpt
          seo {
            metaDesc
            title
          }
          uri
          featuredImage {
            node {
              id
              mediaItemUrl
              altText
            }
          }
        }
      }
    }
  }
`
